import React from "react";
import Typed from "react-typed";

const Main = () => {
  const adjectives = [
    "Great",
    "Excellent",
    "Outstanding",
    "Superb",
    "Marvelous",
    "Fantastic",
    "Perfect",
    "Flawless",
    "Impeccable",
    "Ideal",
    "Faultless",
    "Best",
    "Finest",
    "Optimal",
    "Supreme",
    "Unsurpassed",
    "Exemplary",
    "Exceptional",
    "Remarkable",
    "Phenomenal",
    "Splendid",
    "Praiseworthy",
    "Unbeatable",
  ];

  const work = [
    "software development",
    "quality assurance",
    "continuous integration",
    "test automation",
    "continuous delivery",
  ];

  const getCombinations = (): string[] => {
    const combinations: Map<string, undefined> = new Map();

    do {
      work.forEach((w) => {
        const combination = `${
          adjectives[Math.floor(Math.random() * adjectives.length)]
        } ${w}`;

        if (!combinations.has(combination)) {
          combinations.set(combination, undefined);
        }
      });
    } while (combinations.size < 50);

    return Array.from(combinations.keys());
  };

  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <p className="md:text-5xl sm:text-4xl text-[#00df9a] font-bold p-2">
          e1it
        </p>
        <div className="flex justify-center items-center">
          <Typed
            className="md:text-7xl sm:text-6lx text-4xl font-bold md:-y-6 md:pl-4 pl-2"
            strings={getCombinations()}
            startDelay={200}
            typeSpeed={120}
            backSpeed={50}
            loop
            cursorChar={"_"}
          ></Typed>
        </div>
        <p className="md:text-2xl sm:text-xl font-bold text-gray-500 py-4">
          info@e1it.ca
        </p>
      </div>
    </div>
  );
};

export default Main;
